import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	contractTemplates: {
		path: `/admin/contract-templates`,
		exact: true,
	},
	contractTemplateAdd: {
		path: `/admin/contract-templates/add`,
		exact: true,
	},
	contractTemplateEdit: {
		path: `/admin/contract-templates/:contractTemplateId/edit`,
		params: ['contractTemplateId'] as const,
	},
	invoiceAdd: {
		path: `/account/:accountInfoId/receivables/invoice/add`,
		params: ['accountInfoId'] as const,
	},
	paymentAdd: {
		path: `/account/:accountInfoId/receivables/payment/add`,
		params: ['accountInfoId'] as const,
	},
	transactions: {
		path: `/account/:accountInfoId/receivables/transactions`,
		params: ['accountInfoId'] as const,
	},
	paymentDetails: {
		path: `/account/:accountInfoId/receivables/payment/:paymentId`,
		params: ['accountInfoId', 'paymentId'] as const,
	},
	scheduledPaymentDetails: {
		path: `/account/:accountInfoId/receivables/scheduled-payment/:scheduledPaymentId`,
		params: ['accountInfoId', 'scheduledPaymentId'] as const,
	},
	invoiceDetails: {
		path: `/account/:accountInfoId/receivables/invoice/:invoiceId`,
		params: ['accountInfoId', 'invoiceId'] as const,
	},
	contracts: {
		path: `/account/:accountInfoId/receivables/contract/`,
		params: ['accountInfoId'] as const,
	},
	contractAdd: {
		path: `/account/:accountInfoId/receivables/contract/add`,
		params: ['accountInfoId'] as const,
	},
	contractEdit: {
		path: `/account/:accountInfoId/receivables/contract/:contractId/edit`,
		params: ['accountInfoId', 'contractId'] as const,
	},
	caftRelease: {
		path: `/management/caft/release/:releaseId`,
		params: ['releaseId'] as const,
	},
	caftReleases: {
		path: `/management/caft/releases`,
		exact: true,
	},
	caftOptions: {
		path: `/tools/caftoptions`,
		exact: true,
	},
	papAdd: {
		path: `/account/:accountInfoId/receivables/pap/add`,
		params: ['accountInfoId'] as const,
	},
	papEdit: {
		path: `/account/:accountInfoId/receivables/pap/edit`,
		params: ['accountInfoId'] as const,
	},
});
