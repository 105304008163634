import type {LocalDate} from '@js-joda/core';
import {FrequencyEnum} from '~core/graphql';

// Helper function to get the correct suffix for the day number
function getDaySuffix(day: number): string {
	if (day > 3 && day < 21) return 'th';
	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
}

export function formatRecurringDate(date: LocalDate, frequency: FrequencyEnum): string {
	const day = date.dayOfMonth();
	const month = date.month();

	switch (frequency) {
		case FrequencyEnum.OneTime:
			return `once on ${month.toString()} ${day}, ${date.year()}`;
		case FrequencyEnum.Annually:
			return `annually on ${month.toString()} ${day}${getDaySuffix(day)}`;
		case FrequencyEnum.Quarterly:
			return `quarterly on the ${day}${getDaySuffix(day)} starting in ${month.toString()}`;
		case FrequencyEnum.Monthly:
			return `monthly on the ${day}${getDaySuffix(day)}`;
		default:
			return 'Invalid recurring date';
	}
}
