import {ChronoUnit, LocalDate} from '@js-joda/core';
import {toLocalDate} from '@thx/date';
import {FrequencyEnum} from '~core/graphql';

/**
 * Calculates the next activation date based on the frequency and last generation date.
 *
 * @param {LocalDate} date - The base date for frequency occurrence.
 * @param {FrequencyEnum} frequency - The frequency of occurrence.
 * @param {Date} lastGeneration - The last date/time of activation.
 * @returns {LocalDate} The next activation date.
 */
export function nextGeneration(date: LocalDate, frequency: FrequencyEnum, lastGeneration?: Date | null): LocalDate {
	// If lastGeneration is null, it means this is the first activation
	if (lastGeneration === null) {
		return date;
	}

	// Convert lastGeneration to LocalDate for comparison
	const lastGenLocalDate = LocalDate.from(toLocalDate(lastGeneration));
	// Calculate next quarter from last generation
	const quartersSinceLastGen = Math.floor(lastGenLocalDate.until(date, ChronoUnit.MONTHS) / 3);

	// Determine the next activation date based on frequency
	switch (frequency) {
		case FrequencyEnum.OneTime:
			// If it's one time, we should never activate again after the first time
			// However, for calculation purposes, we'll return the date if lastGeneration was before or on the date
			return lastGenLocalDate.isAfter(date) ? lastGenLocalDate : date;

		case FrequencyEnum.Monthly:
			// Add at least one month since last generation, ensuring we move to the next occurrence
			return lastGenLocalDate.plusMonths(1);

		case FrequencyEnum.Quarterly:
			// Add one quarter to the last generation date, not to the base date
			return lastGenLocalDate.plusMonths((quartersSinceLastGen + 1) * 3);

		case FrequencyEnum.Annually:
			// Add at least one year since last generation
			return lastGenLocalDate.plusYears(1);

		default:
			// This should not happen if FrequencyEnum is exhaustive
			return date;
	}
}
