import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editDefaultBankAccount.graphql';

const defaultOptions = {} as const;
export type EditDefaultBankAccountMutationVariables = Types.Exact<{
	data: Types.DepositAccountInput;
}>;

export type EditDefaultBankAccountMutation = {
	editDepositAccount?: {id: string; version: number; depositAccount?: {id: string; code: number; name: string} | null} | null;
};

export type EditDefaultBankAccountType = {id: string; version: number; depositAccount?: {id: string; code: number; name: string} | null};

export type EditDefaultBankAccountMutationFn = Apollo.MutationFunction<EditDefaultBankAccountMutation, EditDefaultBankAccountMutationVariables>;

/**
 * __useEditDefaultBankAccountMutation__
 *
 * To run a mutation, you first call `useEditDefaultBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultBankAccountMutation, { data, loading, error }] = useEditDefaultBankAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditDefaultBankAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<EditDefaultBankAccountMutation, EditDefaultBankAccountMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditDefaultBankAccountMutation, EditDefaultBankAccountMutationVariables>(Operations, options);
}
export type EditDefaultBankAccountMutationHookResult = ReturnType<typeof useEditDefaultBankAccountMutation>;
export type EditDefaultBankAccountMutationResult = Apollo.MutationResult<EditDefaultBankAccountMutation>;
export type EditDefaultBankAccountMutationOptions = Apollo.BaseMutationOptions<
	EditDefaultBankAccountMutation,
	EditDefaultBankAccountMutationVariables
>;
