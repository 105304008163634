import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPapSidebar.graphql';

const defaultOptions = {} as const;
export type GetPapSidebarQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetPapSidebarQuery = {
	getPreauthorizedPaymentByAccountInfo?: {
		id: string;
		authorizedLimit: Money;
		date: LocalDate;
		frequency: Types.FrequencyEnum;
		lastGeneration?: Date | null;
		status: Types.PapStatusEnum;
	} | null;
};

export type GetPapSidebarType = {
	id: string;
	authorizedLimit: Money;
	date: LocalDate;
	frequency: Types.FrequencyEnum;
	lastGeneration?: Date | null;
	status: Types.PapStatusEnum;
};

/**
 * __useGetPapSidebarQuery__
 *
 * To run a query within a React component, call `useGetPapSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPapSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPapSidebarQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetPapSidebarQuery(baseOptions: Apollo.QueryHookOptions<GetPapSidebarQuery, GetPapSidebarQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPapSidebarQuery, GetPapSidebarQueryVariables>(Operations, options);
}
export function useGetPapSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPapSidebarQuery, GetPapSidebarQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPapSidebarQuery, GetPapSidebarQueryVariables>(Operations, options);
}
export type GetPapSidebarQueryHookResult = ReturnType<typeof useGetPapSidebarQuery>;
export type GetPapSidebarLazyQueryHookResult = ReturnType<typeof useGetPapSidebarLazyQuery>;
export type GetPapSidebarQueryResult = Apollo.QueryResult<GetPapSidebarQuery, GetPapSidebarQueryVariables>;
