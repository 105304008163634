import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPayStubTablePayStubs.graphql';

const defaultOptions = {} as const;
export type GetPayStubTablePayStubsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.PayStubsFilterInput>;
}>;

export type GetPayStubTablePayStubsQuery = {
	getPayStubsByAccount?: Array<{
		id: string;
		version: number;
		cpp: Money;
		cpp2: Money;
		ei: Money;
		endDate: LocalDate;
		payDate: LocalDate;
		startDate: LocalDate;
		tax: Money;
		employee: {
			id: string;
			version: number;
			startDate?: LocalDate | null;
			endDate?: LocalDate | null;
			paymentInterval: Types.EmployeePaymentIntervalEnum;
			contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
		};
		grossIncome: Array<{id: string; version: number; amount: Money; description: string; quantity?: number | null; rates?: number | null}>;
		otherDeductions?: Array<{
			id: string;
			version: number;
			amount: Money;
			description: string;
			quantity?: number | null;
			rates?: number | null;
		}> | null;
		reimbursements?: Array<{id: string; version: number; amount: Money; description: string; quantity?: number | null; rates?: number | null}> | null;
	}> | null;
};

export type GetPayStubTablePayStubsType = {
	id: string;
	version: number;
	cpp: Money;
	cpp2: Money;
	ei: Money;
	endDate: LocalDate;
	payDate: LocalDate;
	startDate: LocalDate;
	tax: Money;
	employee: {
		id: string;
		version: number;
		startDate?: LocalDate | null;
		endDate?: LocalDate | null;
		paymentInterval: Types.EmployeePaymentIntervalEnum;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	};
	grossIncome: Array<{id: string; version: number; amount: Money; description: string; quantity?: number | null; rates?: number | null}>;
	otherDeductions?: Array<{id: string; version: number; amount: Money; description: string; quantity?: number | null; rates?: number | null}> | null;
	reimbursements?: Array<{id: string; version: number; amount: Money; description: string; quantity?: number | null; rates?: number | null}> | null;
};

/**
 * __useGetPayStubTablePayStubsQuery__
 *
 * To run a query within a React component, call `useGetPayStubTablePayStubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubTablePayStubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubTablePayStubsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPayStubTablePayStubsQuery(
	baseOptions: Apollo.QueryHookOptions<GetPayStubTablePayStubsQuery, GetPayStubTablePayStubsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPayStubTablePayStubsQuery, GetPayStubTablePayStubsQueryVariables>(Operations, options);
}
export function useGetPayStubTablePayStubsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetPayStubTablePayStubsQuery, GetPayStubTablePayStubsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPayStubTablePayStubsQuery, GetPayStubTablePayStubsQueryVariables>(Operations, options);
}
export type GetPayStubTablePayStubsQueryHookResult = ReturnType<typeof useGetPayStubTablePayStubsQuery>;
export type GetPayStubTablePayStubsLazyQueryHookResult = ReturnType<typeof useGetPayStubTablePayStubsLazyQuery>;
export type GetPayStubTablePayStubsQueryResult = Apollo.QueryResult<GetPayStubTablePayStubsQuery, GetPayStubTablePayStubsQueryVariables>;
