import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {EmployeeDropdownSidebar} from './components/EmployeeDropdownSidear';
import {routes} from './routes';

const AccountCalendar = loadable(() => import('./components/AccountCalendar'));
const MaintenanceReportTable = loadable(() => import('./components/MaintenanceReportTable'));
const PerDiemReportTable = loadable(() => import('./components/PerDiemReportTable'));
const PvlReportTable = loadable(() => import('./components/PvlReportTable'));

export const routeProps = createPages(routes, {
	calendar: {
		content: () => <AccountCalendar />,
	},
	pvlReport: {
		header: 'PVL Report',
		content: () => <PvlReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	perDiemReport: {
		header: 'Per Diem Report',
		content: () => <PerDiemReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	maintenanceReport: {
		header: 'Maintenance Report',
		content: () => <MaintenanceReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
});
