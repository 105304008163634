import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection, DefaultDateSetting} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.documents.layout');

export const layout: LayoutData = {
	permissions: [Permission.ViewAdminPayrollAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: routes.match.draftDocuments,
			selection: DateRangeSelection.None,
		}),
		dateRangeSelectionHook({
			routeMatch: routes.match.receivedFiles,
			selection: DateRangeSelection.DateRange,
			defaultDateSetting: DefaultDateSetting.TodayFy,
		}),
		dateRangeSelectionHook({routeMatch: routes.match.documentUpload, selection: DateRangeSelection.None}),
	],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Documents',
			stateSelectorHook: [useAccountState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			weight: 4,
			visible: {...isLoggedIn(), ...needsRoute('account'), ...needsPermission(Permission.ViewAdminPayrollAccount)},
			dropdown: [
				{
					text: 'Documents',
					icon: 'file alternate',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {...needsPermission([Permission.ViewAdminPayrollAccount])},
					to: data => (data.state.params?.accountInfoId ? routes.to.documents({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Unsorted Files',
					icon: 'file',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.draftDocuments({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Packages',
					icon: 'box',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.receivedFiles({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Upload Documents',
					icon: 'upload',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.documentUpload({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.BasicAccount]},
					},
					text: 'Upload Log Books',
					icon: 'truck',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.logBookUpload({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Missing Documents',
					icon: 'wait',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.missingDocuments({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
		{
			text: 'Expected Documents',
			icon: 'wait',
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			stateSelectorHook: useLayoutState,
			to: data => (data.state.params?.accountInfoId ? routes.to.viewExpectedDocumentSettings({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
	],
};
