import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	taxCalculator: {
		path: '/tools/tax-calculator',
		exact: true,
	},
	documentTypes: {
		path: '/tools/document-types',
		exact: true,
	},
	documentTypeEdit: {
		path: '/tools/document-types/:typeId/edit',
		params: ['typeId'] as const,
	},
	documentTypeAdd: {
		path: '/tools/document-types/add',
	},
	documentSortingClasses: {
		path: '/tools/documentSortingClasses',
		exact: true,
	},
	documentSortingClassAdd: {
		path: '/tools/documentSortingClasses/add',
	},
	documentSortingClassEdit: {
		path: '/tools/documentSortingClasses/:id/edit',
		params: ['id'] as const,
	},
	globalVendors: {
		path: '/tools/global-vendors',
	},
	addGlobalVendor: {
		path: '/tools/global-vendors/add',
	},
	editGlobalVendor: {
		path: `/tools/global-vendors/:id/edit`,
		params: ['id'] as const,
	},
	mergeGlobalVendor: {
		path: `/tools/global-vendors/merge`,
	},
	indexer: {
		path: '/tools/indexer',
		exact: true,
	},
	processorQueue: {
		path: '/tools/processor-queue',
		exact: true,
	},
	processorErrorLog: {
		path: '/tools/processor-error-log',
		exact: true,
	},
	spacesCleanup: {
		path: '/tools/spaces-cleanup',
		exact: true,
	},
	announcements: {
		path: '/admin/announcements',
		exact: true,
	},
	createAnnouncement: {
		path: '/admin/send-notification',
		exact: true,
	},
	podcasts: {
		path: '/admin/podcasts',
		exact: true,
	},
	podcastAdd: {
		path: '/admin/podcast/add',
		exact: true,
	},
	podcastEdit: {
		path: '/admin/podcasts/:podcastId/edit',
		exact: true,
		params: ['podcastId'] as const,
	},
	errorTraceDecode: {
		path: '/tools/error-trace-decode',
		exact: true,
	},
	accountEmails: {
		path: '/tools/account-emails',
		exact: true,
	},
	fuelPrice: {
		path: '/tools/fuel-price',
		exact: true,
	},
});
