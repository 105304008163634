import {formatDate} from '@thx/date';
import {formatMoney} from '@thx/money';
import {Link} from 'react-router-dom';
import {Button, Grid, Header, Icon, Popup} from 'semantic-ui-react';
import {routes} from '../../../routes';
import {formatRecurringDate} from '../../../utils/formatRecurringDate';
import {nextGeneration} from '../../../utils/nextGeneration';
import {useGetPapSidebarQuery} from './getPapSidebar';

export interface PapSidebarProps {
	accountInfoId: string;
}

export function PapSidebar({accountInfoId}: PapSidebarProps) {
	const {loading, data} = useGetPapSidebarQuery({variables: {accountInfoId}});

	const header = <Header as="h4">Preauthorized Payment</Header>;

	if (loading) {
		return (
			<>
				{header}
				<Icon loading name="circle notched" size="big" color="grey" />
			</>
		);
	}

	if (!data || !data.getPreauthorizedPaymentByAccountInfo) {
		return (
			<>
				{header}
				<Button color="green" as={Link} to={routes.to.papAdd({accountInfoId})}>
					Add preauthorized payment
				</Button>
			</>
		);
	}

	const {date, id, authorizedLimit, status, lastGeneration, frequency} = data.getPreauthorizedPaymentByAccountInfo;
	return (
		<>
			{header}
			<Grid columns={16} style={{width: '18em'}}>
				<Grid.Row style={{paddingBottom: 0, paddingTop: '1em'}}>
					<Grid.Column width={16}>
						Up to {formatMoney(authorizedLimit, true)} {formatRecurringDate(date, frequency)}.
					</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingBottom: 0, paddingTop: '0.6em'}}>
					<Grid.Column width={16}>Status: {status}</Grid.Column>
				</Grid.Row>
				<Grid.Row verticalAlign="middle" style={{paddingBottom: 0, paddingTop: '0.2em'}}>
					<Grid.Column width={12}>Next: {formatDate(nextGeneration(date, frequency, lastGeneration))}</Grid.Column>
					<Grid.Column width={4} textAlign="right">
						<Popup
							content={
								<>
									<Header as="h4">Warning</Header>
									<p>Payments may be queued up to 7 days before this date.</p>
								</>
							}
							trigger={<Icon name="info" circular />}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Button basic compact primary as={Link} to={routes.to.papEdit({accountInfoId})}>
							Edit preauthorized payment
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
}
