import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getChartOfAccountsLinked.graphql';

const defaultOptions = {} as const;
export type GetChartOfAccountsLinkedQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetChartOfAccountsLinkedQuery = {
	getChartOfAccountsByAccountInfoId?: {
		id: string;
		version: number;
		depositAccount?: {id: string; code: number; name: string} | null;
		gstCollectedAccount: {id: string; code: number; name: string};
		gstPaidAccount: {id: string; code: number; name: string};
		receivablesAccount: {id: string; code: number; name: string};
		payablesAccount: {id: string; code: number; name: string};
		retainedEarningsAccount: {id: string; code: number; name: string};
		currentEarningsAccount: {id: string; code: number; name: string};
		perDiemAllowance: {id: string; code: number; name: string};
		pvlAllowance: {id: string; code: number; name: string};
		shareholderLoan: {id: string; code: number; name: string};
		wages: {id: string; name: string; code: number};
		cpp: {id: string; name: string; code: number};
		ei: {id: string; name: string; code: number};
		sourceDeductionsPayable: {id: string; name: string; code: number};
	} | null;
};

export type GetChartOfAccountsLinkedType = {
	id: string;
	version: number;
	depositAccount?: {id: string; code: number; name: string} | null;
	gstCollectedAccount: {id: string; code: number; name: string};
	gstPaidAccount: {id: string; code: number; name: string};
	receivablesAccount: {id: string; code: number; name: string};
	payablesAccount: {id: string; code: number; name: string};
	retainedEarningsAccount: {id: string; code: number; name: string};
	currentEarningsAccount: {id: string; code: number; name: string};
	perDiemAllowance: {id: string; code: number; name: string};
	pvlAllowance: {id: string; code: number; name: string};
	shareholderLoan: {id: string; code: number; name: string};
	wages: {id: string; name: string; code: number};
	cpp: {id: string; name: string; code: number};
	ei: {id: string; name: string; code: number};
	sourceDeductionsPayable: {id: string; name: string; code: number};
};

/**
 * __useGetChartOfAccountsLinkedQuery__
 *
 * To run a query within a React component, call `useGetChartOfAccountsLinkedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartOfAccountsLinkedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartOfAccountsLinkedQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetChartOfAccountsLinkedQuery(
	baseOptions: Apollo.QueryHookOptions<GetChartOfAccountsLinkedQuery, GetChartOfAccountsLinkedQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetChartOfAccountsLinkedQuery, GetChartOfAccountsLinkedQueryVariables>(Operations, options);
}
export function useGetChartOfAccountsLinkedLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetChartOfAccountsLinkedQuery, GetChartOfAccountsLinkedQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetChartOfAccountsLinkedQuery, GetChartOfAccountsLinkedQueryVariables>(Operations, options);
}
export type GetChartOfAccountsLinkedQueryHookResult = ReturnType<typeof useGetChartOfAccountsLinkedQuery>;
export type GetChartOfAccountsLinkedLazyQueryHookResult = ReturnType<typeof useGetChartOfAccountsLinkedLazyQuery>;
export type GetChartOfAccountsLinkedQueryResult = Apollo.QueryResult<GetChartOfAccountsLinkedQuery, GetChartOfAccountsLinkedQueryVariables>;
