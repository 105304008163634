import {createSliceHook} from '@imperium/state';
import type {LocalDate} from '@js-joda/core';
/* eslint-disable no-param-reassign */
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {toLocalDate} from '@thx/date';
import {toMoney} from '@thx/money';
import type Money from 'js-money';
import type {GeneralLedgerAccountGroupEnum} from '~core/graphql';

interface AmountFilterInputMoney {
	fromAmount?: Money;
	toAmount?: Money;
}

interface AmountFilterString {
	fromAmount?: string;
	toAmount?: string;
}

interface PayStatementLine {
	glAccount?: string;
	uiTotal: string;
	total: string;
	gst?: string;
}

interface PayStatementLineInput {
	glAccount?: {
		id: string;
		group: GeneralLedgerAccountGroupEnum;
	};
	uiTotal: Money;
	total: Money;
	gst?: Money;
}

interface GeneralJournalFilter {
	comment?: string;
	showDetailed?: boolean;
	showLines?: boolean;
	glAccounts?: string[];
}

interface GeneralLedgerFilter {
	showDetailed?: boolean;
	glAccounts?: string[];
}

interface PayablesFilter {
	invoiceNumber?: string;
	showPaid?: boolean;
	showOutstandingFromPreviousYears?: boolean;
	vendor?: string;
}

interface PaymentsFilter {
	invoiceNumber?: string;
	paymentGlAccount?: string;
	vendor?: string;
}

interface ReceivablesFilter {
	invoiceNumber?: string;
	showPaid?: boolean;
	showOutstandingFromPreviousYears?: boolean;
	vendor?: string;
}

interface ReceiptsFilter {
	invoiceNumber?: string;
	receiptGlAccount?: string;
	vendor?: string;
}

interface PayableTemplatesFilter {
	vendor?: string;
}

interface ReceivableTemplatesFilter {
	vendor?: string;
}

export const state = createSlice({
	name: 'accounting',
	initialState: {
		payStatementLines: [] as PayStatementLine[],
		showExpensesDialog: false,
		generalJournalFilter: {
			comment: undefined,
			showDetailed: false,
			showLines: true,
			glAccounts: undefined as string[] | undefined,
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
		} as GeneralJournalFilter & AmountFilterString,
		generalLedgerFilter: {
			showDetailed: false,
			glAccounts: undefined as string[] | undefined,
		} as GeneralLedgerFilter,
		payablesFilter: {
			invoiceNumber: undefined,
			showPaid: false,
			showOutstandingFromPreviousYears: true,
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as PayablesFilter & AmountFilterString,
		paymentsFilter: {
			invoiceNumber: undefined,
			paymentGlAccount: undefined,
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as PaymentsFilter & AmountFilterString,
		receivablesFilter: {
			invoiceNumber: undefined,
			showPaid: false,
			showOutstandingFromPreviousYears: true,
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as ReceivablesFilter & AmountFilterString,
		receiptsFilter: {
			invoiceNumber: undefined,
			receiptGlAccount: undefined,
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as ReceiptsFilter & AmountFilterString,
		journalEntryTemplatesFilter: {
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
		} as AmountFilterString,
		payableTemplatesFilter: {
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as PayableTemplatesFilter & AmountFilterString,
		receivableTemplatesFilter: {
			fromAmount: undefined as string | undefined,
			toAmount: undefined as string | undefined,
			vendor: undefined as string | undefined,
		} as ReceivableTemplatesFilter & AmountFilterString,
		archivedVendors: false,
		archivedGlobalVendors: false,
		fiscalOpenDate: undefined as number | undefined,
		historicClosingDate: undefined as number | undefined,
	},
	reducers: {
		// needed for sidebar summary
		setPayStatementLines: {
			reducer: (st, action: PayloadAction<PayStatementLine[]>) => {
				st.payStatementLines = action.payload;
			},
			prepare: (payStatementLines: PayStatementLineInput[]) => ({
				payload: payStatementLines.map(line => ({
					uiTotal: JSON.stringify(line.uiTotal),
					total: JSON.stringify(line.total),
					gst: line?.gst ? JSON.stringify(line.gst) : undefined,
					glAccount: line?.glAccount ? JSON.stringify(line.glAccount) : undefined,
				})),
			}),
		},
		setShowExpensesDialog: (st, action: PayloadAction<boolean>) => {
			st.showExpensesDialog = action.payload;
		},
		setGeneralJournalFilter: {
			reducer: (st, action: PayloadAction<GeneralJournalFilter & AmountFilterString>) => {
				st.generalJournalFilter = {...st.generalJournalFilter, ...action.payload};
			},
			prepare: (filter: GeneralJournalFilter & AmountFilterInputMoney) => {
				let filteredAmounts: AmountFilterString = {};
				if (filter.fromAmount) filteredAmounts = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredAmounts = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredAmounts,
					} as GeneralJournalFilter & AmountFilterString,
				};
			},
		},
		setGeneralLedgerFilter: {
			reducer: (st, action: PayloadAction<GeneralLedgerFilter & AmountFilterString>) => {
				st.generalLedgerFilter = {...st.generalLedgerFilter, ...action.payload};
			},
			prepare: (filter: GeneralLedgerFilter) => {
				return {
					payload: filter,
				};
			},
		},
		clearGeneralJournalFilter: st => {
			st.generalJournalFilter = {
				comment: undefined,
				showDetailed: false,
				showLines: true,
				fromAmount: undefined,
				toAmount: undefined,
				glAccounts: undefined,
			};
		},
		clearGeneralLedgerFilter: st => {
			st.generalLedgerFilter = {
				showDetailed: false,
				glAccounts: undefined,
			};
		},
		setPayablesFilter: {
			reducer: (st, action: PayloadAction<PayablesFilter & AmountFilterString>) => {
				st.payablesFilter = {...st.payablesFilter, ...action.payload};
			},
			prepare: (filter: PayablesFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as PayablesFilter & AmountFilterString,
				};
			},
		},
		clearPayablesFilter: st => {
			st.payablesFilter = {
				showPaid: false,
				showOutstandingFromPreviousYears: true,
				invoiceNumber: undefined,
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setPaymentsFilter: {
			reducer: (st, action: PayloadAction<PaymentsFilter & AmountFilterString>) => {
				st.paymentsFilter = {...st.paymentsFilter, ...action.payload};
			},
			prepare: (filter: PaymentsFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as PaymentsFilter & AmountFilterString,
				};
			},
		},
		clearPaymentsFilter: st => {
			st.paymentsFilter = {
				invoiceNumber: undefined,
				paymentGlAccount: undefined,
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setReceivablesFilter: {
			reducer: (st, action: PayloadAction<ReceivablesFilter & AmountFilterString>) => {
				st.receivablesFilter = {...st.receivablesFilter, ...action.payload};
			},
			prepare: (filter: ReceivablesFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as ReceivablesFilter & AmountFilterString,
				};
			},
		},
		clearReceivablesFilter: st => {
			st.receivablesFilter = {
				showPaid: false,
				invoiceNumber: undefined,
				showOutstandingFromPreviousYears: true,
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setReceiptsFilter: {
			reducer: (st, action: PayloadAction<ReceiptsFilter & AmountFilterString>) => {
				st.receiptsFilter = {...st.receiptsFilter, ...action.payload};
			},
			prepare: (filter: ReceiptsFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as ReceiptsFilter & AmountFilterString,
				};
			},
		},
		clearReceiptsFilter: st => {
			st.receiptsFilter = {
				invoiceNumber: undefined,
				receiptGlAccount: undefined,
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setJournalEntryTemplatesFilter: {
			reducer: (st, action: PayloadAction<AmountFilterString>) => {
				st.journalEntryTemplatesFilter = {...st.journalEntryTemplatesFilter, ...action.payload};
			},
			prepare: (filter: AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filteredDates,
					},
				};
			},
		},
		clearJournalEntryTemplatesFilter: st => {
			st.journalEntryTemplatesFilter = {
				fromAmount: undefined,
				toAmount: undefined,
			};
		},
		setPayableTemplatesFilter: {
			reducer: (st, action: PayloadAction<PayableTemplatesFilter & AmountFilterString>) => {
				st.payableTemplatesFilter = {...st.payableTemplatesFilter, ...action.payload};
			},
			prepare: (filter: PayableTemplatesFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as PayableTemplatesFilter & AmountFilterString,
				};
			},
		},
		clearPayableTemplatesFilter: st => {
			st.payableTemplatesFilter = {
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setReceivableTemplatesFilter: {
			reducer: (st, action: PayloadAction<ReceivableTemplatesFilter & AmountFilterString>) => {
				st.receivableTemplatesFilter = {...st.receivableTemplatesFilter, ...action.payload};
			},
			prepare: (filter: ReceivableTemplatesFilter & AmountFilterInputMoney) => {
				let filteredDates: AmountFilterString = {};
				if (filter.fromAmount) filteredDates = {fromAmount: JSON.stringify(filter?.fromAmount)};
				if (filter.toAmount) filteredDates = {toAmount: JSON.stringify(filter?.toAmount)};

				return {
					payload: {
						...filter,
						...filteredDates,
					} as ReceivableTemplatesFilter & AmountFilterString,
				};
			},
		},
		clearReceivableTemplatesFilter: st => {
			st.receivableTemplatesFilter = {
				fromAmount: undefined,
				toAmount: undefined,
				vendor: undefined,
			};
		},
		setArchivedVendors: (st, action: PayloadAction<boolean>) => {
			st.archivedVendors = action.payload;
		},
		setArchivedGlobalVendors: (st, action: PayloadAction<boolean>) => {
			st.archivedGlobalVendors = action.payload;
		},
		setFiscalOpenDate: {
			reducer: (st, action: PayloadAction<number | undefined>) => {
				st.fiscalOpenDate = action.payload;
			},
			prepare: (fiscalOpenDate: LocalDate | null) => ({
				payload: fiscalOpenDate?.toEpochDay(),
			}),
		},
		setHistoricClosingDate: {
			reducer: (st, action: PayloadAction<number | undefined>) => {
				st.historicClosingDate = action.payload;
			},
			prepare: (historicClosingDate: LocalDate | null) => ({
				payload: historicClosingDate?.toEpochDay(),
			}),
		},
	},
});

export const useAccountingState = createSliceHook(state, {
	payStatementLines: payStatementLines =>
		payStatementLines.map(line => ({
			uiTotal: toMoney(line?.uiTotal ? JSON.parse(line.uiTotal) : 0),
			total: toMoney(line?.total ? JSON.parse(line.total) : 0),
			gst: toMoney(line?.gst ? JSON.parse(line.gst) : 0),
			glAccount: line.glAccount ? JSON.parse(line.glAccount) : undefined,
		})),
	fiscalOpenDate: fiscalOpenDate => (fiscalOpenDate ? toLocalDate(fiscalOpenDate) : undefined),
	historicClosingDate: historicClosingDate => (historicClosingDate ? toLocalDate(historicClosingDate) : undefined),
	generalJournalFilter: v => ({
		...v,
		showLines: v.showLines,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	payablesFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	receivablesFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	paymentsFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	receiptsFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	journalEntryTemplatesFilter: v => ({
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	payableTemplatesFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
	receivableTemplatesFilter: v => ({
		...v,
		toAmount: v.toAmount ? toMoney(JSON.parse(v.toAmount)) : undefined,
		fromAmount: v.fromAmount ? toMoney(JSON.parse(v.fromAmount)) : undefined,
	}),
});

export const {
	setPayStatementLines,
	setShowExpensesDialog,
	clearGeneralLedgerFilter,
	clearGeneralJournalFilter,
	setPayablesFilter,
	setPaymentsFilter,
	setReceivablesFilter,
	setReceiptsFilter,
	setArchivedVendors,
	setArchivedGlobalVendors,
	setJournalEntryTemplatesFilter,
	setPayableTemplatesFilter,
	setReceivableTemplatesFilter,
	clearPayablesFilter,
	clearPaymentsFilter,
	clearReceivablesFilter,
	clearReceiptsFilter,
	clearPayableTemplatesFilter,
	clearReceivableTemplatesFilter,
	clearJournalEntryTemplatesFilter,
	setGeneralJournalFilter,
	setGeneralLedgerFilter,
	setFiscalOpenDate,
	setHistoricClosingDate,
} = state.actions;
