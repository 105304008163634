import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.assets.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: routes.match.corporateAssets,
			selection: DateRangeSelection.None,
		}),
		dateRangeSelectionHook({
			routeMatch: routes.match.personalVehicles,
			selection: DateRangeSelection.None,
		}),
		dateRangeSelectionHook({
			routeMatch: routes.match.financialAccounts,
			selection: DateRangeSelection.None,
		}),
	],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Assets',
			icon: 'building',
			stateSelectorHook: [useLayoutState, useAccountState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {
				...isLoggedIn(),
				...needsRoute('account'),
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
			},
			weight: 2,
			dropdown: [
				{
					text: 'Financial Accounts',
					icon: 'building',
					stateSelectorHook: [useLayoutState, useAccountState],
					permissionSelectorHook: [
						data => {
							const [viewAdminPayrollAccount] = useCan([
								{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
							]);
							return {viewAdminPayrollAccount};
						},
					],
					to: data => (data.state.params?.accountInfoId ? routes.to.financialAccounts({accountInfoId: data.state.params.accountInfoId}) : ''),
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
					},
				},
				{
					text: 'Corporate Assets',
					icon: 'truck',
					stateSelectorHook: [useLayoutState, useAccountState],
					permissionSelectorHook: [
						data => {
							const [viewAdminPayrollAccount] = useCan([
								{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
							]);
							return {viewAdminPayrollAccount};
						},
					],
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						...isLoggedIn(),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
					},
					to: data => (data.state.params?.accountInfoId ? routes.to.corporateAssets({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Personal Vehicles',
					icon: 'car',
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						...isLoggedIn(),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.personalVehicles({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
	],
};
