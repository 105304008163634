import type React from 'react';
import {useDispatch} from 'react-redux';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {setArchivedGlobalVendors, useAccountingState} from '../../../accounting/state';

export function GlobalVendorsTableSidebar() {
	const dispatch = useDispatch();
	const {archivedGlobalVendors} = useAccountingState();

	return (
		<Form>
			<FormInput label="Status">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Active', value: false},
						{id: 2, text: 'Archived', value: true},
					]}
					value={archivedGlobalVendors}
					onChange={(_e, data) => dispatch(setArchivedGlobalVendors(data.value as boolean))}
				/>
			</FormInput>
		</Form>
	);
}
