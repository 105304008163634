import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getGeneralLedgerAccountsTemplateTable.graphql';

const defaultOptions = {} as const;
export type GetGeneralLedgerAccountsTemplateTableTableQueryVariables = Types.Exact<{
	chartOfAccountsTemplateId: Types.Scalars['String'];
}>;

export type GetGeneralLedgerAccountsTemplateTableTableQuery = {
	getChartOfAccountsTemplateById?: {
		id: string;
		version: number;
		glAccountsTemplates?: Array<{
			id: string;
			version: number;
			name: string;
			code: number;
			type: Types.GeneralLedgerAccountTypeEnum;
			description?: string | null;
		}> | null;
		currentEarningsAccount: {id: string; name: string; code: number};
		gstCollectedAccount: {id: string; name: string; code: number};
		gstPaidAccount: {id: string; name: string; code: number};
		payablesAccount: {id: string; name: string; code: number};
		receivablesAccount: {id: string; name: string; code: number};
		retainedEarningsAccount: {id: string; name: string; code: number};
		perDiemAllowance: {id: string; name: string; code: number};
		pvlAllowance: {id: string; name: string; code: number};
		shareholderLoan: {id: string; name: string; code: number};
		wages: {id: string; name: string; code: number};
		cpp: {id: string; name: string; code: number};
		ei: {id: string; name: string; code: number};
		sourceDeductionsPayable: {id: string; name: string; code: number};
	} | null;
};

export type GetGeneralLedgerAccountsTemplateTableTableType = {
	id: string;
	version: number;
	glAccountsTemplates?: Array<{
		id: string;
		version: number;
		name: string;
		code: number;
		type: Types.GeneralLedgerAccountTypeEnum;
		description?: string | null;
	}> | null;
	currentEarningsAccount: {id: string; name: string; code: number};
	gstCollectedAccount: {id: string; name: string; code: number};
	gstPaidAccount: {id: string; name: string; code: number};
	payablesAccount: {id: string; name: string; code: number};
	receivablesAccount: {id: string; name: string; code: number};
	retainedEarningsAccount: {id: string; name: string; code: number};
	perDiemAllowance: {id: string; name: string; code: number};
	pvlAllowance: {id: string; name: string; code: number};
	shareholderLoan: {id: string; name: string; code: number};
	wages: {id: string; name: string; code: number};
	cpp: {id: string; name: string; code: number};
	ei: {id: string; name: string; code: number};
	sourceDeductionsPayable: {id: string; name: string; code: number};
};

/**
 * __useGetGeneralLedgerAccountsTemplateTableTableQuery__
 *
 * To run a query within a React component, call `useGetGeneralLedgerAccountsTemplateTableTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralLedgerAccountsTemplateTableTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralLedgerAccountsTemplateTableTableQuery({
 *   variables: {
 *      chartOfAccountsTemplateId: // value for 'chartOfAccountsTemplateId'
 *   },
 * });
 */
export function useGetGeneralLedgerAccountsTemplateTableTableQuery(
	baseOptions: Apollo.QueryHookOptions<GetGeneralLedgerAccountsTemplateTableTableQuery, GetGeneralLedgerAccountsTemplateTableTableQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetGeneralLedgerAccountsTemplateTableTableQuery, GetGeneralLedgerAccountsTemplateTableTableQueryVariables>(
		Operations,
		options,
	);
}
export function useGetGeneralLedgerAccountsTemplateTableTableLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetGeneralLedgerAccountsTemplateTableTableQuery,
		GetGeneralLedgerAccountsTemplateTableTableQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetGeneralLedgerAccountsTemplateTableTableQuery, GetGeneralLedgerAccountsTemplateTableTableQueryVariables>(
		Operations,
		options,
	);
}
export type GetGeneralLedgerAccountsTemplateTableTableQueryHookResult = ReturnType<typeof useGetGeneralLedgerAccountsTemplateTableTableQuery>;
export type GetGeneralLedgerAccountsTemplateTableTableLazyQueryHookResult = ReturnType<typeof useGetGeneralLedgerAccountsTemplateTableTableLazyQuery>;
export type GetGeneralLedgerAccountsTemplateTableTableQueryResult = Apollo.QueryResult<
	GetGeneralLedgerAccountsTemplateTableTableQuery,
	GetGeneralLedgerAccountsTemplateTableTableQueryVariables
>;
