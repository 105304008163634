import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Header, List} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import type {GeneralLedgerAccount} from '~core/graphql';
import {formatGlAccount} from '~lib/formatGlAccount';
import {routes} from '../../../routes';
import {useAccountingState} from '../../../state';
import {ChooseDefaultBankAccount} from '../ChooseDefaultBankAccount/ChooseDefaultBankAccount';
import {useGetChartOfAccountsLinkedQuery} from './getChartOfAccountsLinked';

const d = debug('tacs.web.accounting.components.chartOfAccounts.GeneralLedgerAccountsTableSideBar');

export function GeneralLedgerAccountsTableSideBar() {
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.chartOfAccounts>();
	const {historicClosingDate} = useAccountingState();
	const {data, loading} = useGetChartOfAccountsLinkedQuery({
		variables: {
			accountInfoId,
		},
	});

	function objectType(object: any): object is GeneralLedgerAccount {
		return typeof object?.id === 'string' && typeof object?.code === 'number';
	}

	return (
		<List>
			{!historicClosingDate && (
				<List.Item>
					<Button fluid color="teal" onClick={() => push(routes.to.openingBalances({accountInfoId}))}>
						Enter Opening Balances
					</Button>
				</List.Item>
			)}
			{!historicClosingDate && (
				<List.Item>
					<Divider />
				</List.Item>
			)}
			<List.Item>
				<ChooseDefaultBankAccount
					queryLoading={loading}
					accountInfoId={accountInfoId}
					defaultBankAccountId={data?.getChartOfAccountsByAccountInfoId?.depositAccount?.id}
				/>
			</List.Item>
			<List.Item>
				<Header as="h4">Default Accounts</Header>
			</List.Item>
			{Object.values(data?.getChartOfAccountsByAccountInfoId || {})?.map((item: GeneralLedgerAccount | unknown) => {
				if (objectType(item)) {
					return (
						<List.Item key={item.id}>
							<ColoredSpan labelText={item.name} contentText={formatGlAccount(item) || 'Not Set'} />
						</List.Item>
					);
				}
				return null;
			})}
		</List>
	);
}
