import React, {useMemo} from 'react';
import {Select, StrictSelectProps} from 'semantic-ui-react';
import {GeneralLedgerAccountTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetChartOfAccountsByAccountInfoIdQuery} from '../generalJournal/graphql/getChartOfAccountsByAccountInfoId';

const PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE = {
	MIN: 1000,
	MAX: 1099,
};

interface PaymentGlAccountDropdownProps {
	accountInfoId: string;
	defaultGlAccountId?: string;
	openOnly?: boolean;
	hideReceivablesGl?: boolean;
}

export function PaymentReceiptGlAccountDropdown(props: PaymentGlAccountDropdownProps & Omit<StrictSelectProps, 'options'>) {
	const {accountInfoId, defaultGlAccountId, openOnly, error, value, hideReceivablesGl, ...extraProps} = props;
	const throwError = useAsyncError();
	const {
		loading: chartOfAccountsLoading,
		error: chartOfAccountsError,
		data: chartOfAccountsData,
	} = useGetChartOfAccountsByAccountInfoIdQuery({
		variables: {
			accountInfoId,
			filter: {
				defaultGlAccountId,
				openOnly,
			},
		},
	});
	if (chartOfAccountsError) throwError(chartOfAccountsError);

	const glAccountOptions = useMemo(() => {
		return (
			chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.glAccounts
				?.filter(
					glAcc =>
						(glAcc.type === GeneralLedgerAccountTypeEnum.Account || glAcc.type === GeneralLedgerAccountTypeEnum.Subaccount) &&
						glAcc.code >= PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE.MIN &&
						glAcc.code <= PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE.MAX &&
						(hideReceivablesGl ? glAcc.id !== chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.receivablesAccount?.id : true),
				)
				.sort((a, b) => (a.code > b.code ? 1 : -1))
				.map(glAcc => ({
					key: glAcc.id,
					value: glAcc.id,
					text: `${glAcc.code} - ${glAcc.name}`,
				})) || []
		);
	}, [
		chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.glAccounts,
		chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.receivablesAccount?.id,
		hideReceivablesGl,
	]);

	return (
		<Select
			{...extraProps}
			value={value}
			error={error || (openOnly && chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.glAccounts?.some(gl => gl.id === value && gl.closedDate))}
			loading={chartOfAccountsLoading}
			options={glAccountOptions}
		/>
	);
}
